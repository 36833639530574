<template>
  <div class="card mb-4">
    <div class="card-header pb-0">
      <h6>Tabla de usuarios</h6>
      <!-- <button class="btn">Cargar usuarios</button> -->
      <!-- Button trigger modal -->
      <button
        type="button"
        class="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        Cargar rol
      </button>

      <!-- Modal -->
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="2"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Cargar rol</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <form @submit.prevent="addRole">
                <div class="form-group">
                  <label for="nombre">Nombre</label>
                  <input
                    type="text"
                    class="form-control"
                    id="nombre"
                    placeholder="Nombre"
                    v-model.trim="name"
                    required
                  />
                </div>
                <div class="form-group">
                  <label for="descripcion">Descripcion</label>
                  <input
                    type="text"
                    class="form-control"
                    id="descripcion"
                    placeholder="Descripcion"
                    v-model.trim="description"
                    required
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Privilegio</label>
                  <input
                    type="text"
                    class="form-control"
                    aria-describedby="emailHelp"
                    id="privilegio"
                    placeholder="Privilegio"
                    v-model.trim="privilege"
                    required
                  />
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Cancelar
                  </button>
                  <button type="submit" class="btn btn-primary">Guardar</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="editModal"
        tabindex="2"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Editar rol</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <form>
                <div class="form-group">
                  <label for="nombre">Nombre</label>
                  <input
                    type="text"
                    class="form-control"
                    id="nombre"
                    placeholder="Nombre"
                    v-model="currentRole.name"
                  />
                </div>
                <div class="form-group">
                  <label for="descripcion">Descripcion</label>
                  <input
                    type="text"
                    class="form-control"
                    id="descripcion"
                    placeholder="Descripcion"
                    v-model="currentRole.description"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Privilegio</label>
                  <input
                    type="text"
                    class="form-control"
                    aria-describedby="emailHelp"
                    id="privilegio"
                    placeholder="Privilegio"
                    v-model="currentRole.privilege"
                  />
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Cancelar
                  </button>
                  <button 
                    type="button" 
                    v-on:click="guardarRole" 
                    class="btn btn-primary">Guardar</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Nombre
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Descripcion
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Privilegio
              </th>
              <!-- <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Pais
              </th> -->
              <th class="text-secondary opacity-7"></th>
            </tr>
          </thead>
          <tbody>
            <tr 
              v-for="role in dataRoles" 
              :key="role.id"
              >
              <td>
                <div class="d-flex px-2 py-1">
                  <div class="d-flex flex-column justify-content-center">
                    <p class="text-xs text-secondary mb-0">{{role.name}}</p>
                  </div>
                </div>
              </td>
              <td>
                <p class="text-xs text-secondary mb-0">{{role.description}}</p>
              </td>
              <td class="align-middle text-center text-sm">
                <p class="text-xs text-secondary mb-0">{{role.privilege}}</p>
              </td>
              <td class="align-middle text-center">
                <button
                  type="button"
                  class="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#editModal"
                  @click="editRole(role)"
                >
                  Editar
                </button>
              </td>
              <td class="align-middle text-center">
                <button
                  type="button"
                  class="btn btn-danger"
                  @click="deleteRole(role)"
                >
                  Eliminar
                </button>
              </td>

              <td class="align-middle"></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: "authors-table",
  data: () => ({
    name: '',
    description: '',
    privilege: '',
    dataRoles: [],
    currentRole: {
      name: '',
      description: '',
      privilege: ''
    }
  }),
  mounted() {
    this.getRole()
  },
  methods: {
    async addRole() {
      try {
        const getToken = {
          headers: {
            Authorization: process.env.VUE_APP_WP_API_KEY,
            'Content-Type': 'application/json; charset=utf-8'
          }
        }
        const role = {
          name: this.name,
          description: this.description,
          privilege: this.privilege
        }
        this.dataRoles.push(role)        
        this.name = '',
        this.description = '',
        this.privilege = ''
        await axios.post('http://localhost:5000/api/roles', {role}, getToken)
          .then(response => {
            console.log(response.data)
            response.data})

      } catch (error){
         console.log(error.response.data);  
         console.log(error.response.status);  
         console.log(error.response.headers);
      }
    },
    async getRole() {
      try {
        const getToken = {
          headers: {
            Authorization: process.env.VUE_APP_WP_API_KEY,
            'Content-Type': 'application/json; charset=utf-8'
          }
        }

        this.dataRoles = await axios.get('http://localhost:5000/api/roles', getToken)
        .then(response => response.data.roles)
      } catch (error) {
         console.log(error.response.data);  
         console.log(error.response.status);  
         console.log(error.response.headers); 
      }
    },
    editRole(role) {
      this.currentRole = {
        name: role.name,
        description: role.description,
        privilege: role.privilege,
        id: role.id,
      }
      console.log(role.id)
    },
    async guardarRole() {
      axios({
          method: 'put',
          url: 'http://localhost:5000/api/roles/' + this.currentRole.id,
          data: {
              fieldsToUpdate: this.currentRole
          },
          headers: {
            Authorization: process.env.VUE_APP_WP_API_KEY,
            'Content-Type': 'application/json; charset=utf-8'
          },
      }).then(() => {
        this.dataRoles = this.dataRoles.map(role => {
          if (role.id === this.currentRole.id) {
            return this.currentRole
          } else {
            return role
          }
        })
      });
    },
    deleteRole(role) {
      axios({
          method: 'delete',
          url: 'http://localhost:5000/api/roles/' + role.id,
          headers: {
            Authorization: process.env.VUE_APP_WP_API_KEY,
            'Content-Type': 'application/json; charset=utf-8'
          },
      }).then(() => {
        this.dataRoles = this.dataRoles.filter(r => {
          if (r.id !== role.id) {
            return r
          }
        })
      });
    }
  }
};
</script>

<style>
.btn-cargar-user {
  border: none;
  margin-bottom: 10px;
  font-size: 13px;
  border-radius: 10px;
  width: 125px;
  height: 40px;
}
</style>
