<template>
    <div class="login">
        <h1>Login</h1>
        <div class="form">
            <label for="username">Username</label>
            <input v-model="username" type="text" name="username" class="input">
            <label for="password">Password</label>
            <input v-model="password" type="password" name="password" class="input">
            <button @click="login()" class="login">Login</button>
        </div>
    </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
    
    name: "LoginAuthPage",
    data(){
        return {
            username: null,
            password: null
        }
    },
    methods:{
        ...mapActions(["setAuthUser"]),
        login(){
            console.log("Login");
            /// Authenticate against API
            //Mutation $store.commit('agregar2')
            //store.user_login_auth = this.username;
            this.setAuthUser(this.username);
            this.$router.push("/tests/onlyauthpage");
        }
    }
}
</script>
<style scoped>
.form{
    display: flex;
    flex-direction: column;
    max-width: 400px;
    margin: 0 auto;
}
</style>