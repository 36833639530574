<template>
  <div class="card mb-4">
    <div class="card-header pb-0">
      <h6>Tabla de usuarios</h6>
      <!-- <button class="btn">Cargar usuarios</button> -->
      <!-- Button trigger modal -->
      <button
        type="button"
        class="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        Cargar disertantes
      </button>

      <!-- Modal -->
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="2"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Cargar disertante
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <form @submit.prevent="addSpeaker">
                <div class="form-group">
                  <label for="nombre">Nombre</label>
                  <input
                    type="text"
                    class="form-control"
                    id="nombre"
                    placeholder="Nombre"
                    v-model.trim="name"
                  />
                </div>
                <div class="form-group">
                  <label for="apellido">Apellido</label>
                  <input
                    type="text"
                    class="form-control"
                    id="apellido"
                    placeholder="Apellido"
                    v-model.trim="lastName"
                  />
                </div>
                <div class="form-group">
                  <label for="descripcion">Descripcion</label>
                  <input
                    type="text"
                    class="form-control"
                    id="descripcion"
                    placeholder="Descripcion"
                    v-model.trim="description"
                  />
                </div>
                <button class="btn btn-primary">Cargar imagen</button>
                <div class="form-group">
                  <label for="perfil">Perfil de Linkedin</label>
                  <input
                    type="text"
                    class="form-control"
                    id="perfil"
                    placeholder="Perfil de Linkedin"
                    v-model.trim="profile"
                  />
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Cancelar
                  </button>
                  <button type="submit" class="btn btn-primary">Guardar</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="editModal"
        tabindex="2"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Editar disertante
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <form>
                <div class="form-group">
                  <label for="nombre">Nombre</label>
                  <input
                    type="text"
                    class="form-control"
                    id="nombre"
                    placeholder="Nombre"
                    v-model="currentSpeaker.name"
                  />
                </div>
                <div class="form-group">
                  <label for="apellido">Apellido</label>
                  <input
                    type="text"
                    class="form-control"
                    id="apellido"
                    placeholder="Apellido"
                    v-model="currentSpeaker.lastName"
                  />
                </div>
                <div class="form-group">
                  <label for="descripcion">Descripcion</label>
                  <input
                    type="text"
                    class="form-control"
                    id="descripcion"
                    placeholder="Descripcion"
                    v-model="currentSpeaker.description"
                  />
                </div>
                <button class="btn btn-primary">Cargar imagen</button>
                <div class="form-group">
                  <label for="perfil">Perfil de Linkedin</label>
                  <input
                    type="text"
                    class="form-control"
                    id="perfil"
                    placeholder="Perfil de Linkedin"
                    v-model="currentSpeaker.profile"
                  />
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Cancelar
                  </button>
                  <button 
                    type="button" 
                    class="btn btn-primary"
                    v-on:click="guardarSpeaker"
                    >Guardar</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Usuario
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Descripcion
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Link al perfil
              </th>
              <th class="text-secondary opacity-7"></th>
            </tr>
          </thead>
          <tbody>
            <tr 
              v-for="speaker in dataSpeakers" 
              :key="speaker.id">
              <td>
                <div class="d-flex px-2 py-1">
                  <div>
                    <vsud-avatar
                      :img="img1"
                      size="sm"
                      border-radius="lg"
                      class="me-3"
                      alt="user1"
                    />
                  </div>
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">{{speaker.name}} {{speaker.lastName}}</h6>
                  </div>
                </div>
              </td>
              <td>
                <p class="text-xs text-secondary mb-0">{{speaker.description}}</p>
              </td>
              <td class="align-middle text-center text-sm">
                <p class="text-xs text-secondary mb-0">{{speaker.profile}}</p>
              </td>
              <td class="align-middle text-center">
                <button
                  type="button"
                  class="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#editModal"
                  @click="editSpeaker(speaker)"
                >
                  Editar
                </button>
              </td>
              <td class="align-middle text-center">
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="deleteSpeaker(speaker)"
                >
                  Eliminar
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import VsudAvatar from "@/components/VsudAvatar.vue";
// import VsudBadge from "@/components/VsudBadge.vue";
import img1 from "../assets/img/team-2.jpg";
import img2 from "../assets/img/team-3.jpg";
import img3 from "../assets/img/team-4.jpg";
import img4 from "../assets/img/team-3.jpg";
import img5 from "../assets/img/team-2.jpg";
import img6 from "../assets/img/team-4.jpg";

import axios from 'axios'

export default {
  name: "authors-table",
  data: () => ({
    name: '',
    lastName: '',
    description: '',
    profile: '',
    dataSpeakers: [],
    currentSpeaker: {
      name: '',
      lastName: '',
      description: '',
      profile: '',
    },
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
  }),
  components: {
    VsudAvatar,
    // VsudBadge,
  },
  mounted() {
    this.getSpeaker()
  },
  methods: {
    async addSpeaker() {
      try {
        const getToken = {
          headers: {
            Authorization: process.env.VUE_APP_WP_API_KEY,
            'Content-Type': 'application/json; charset=utf-8'
          }
        }
        const speaker = {
          name: this.name,
          lastName: this.lastName,
          description: this.description,
          profile: this.profile
        }
        this.dataSpeakers.push(speaker)        
        this.name = '',
        this.lastName = '',
        this.description = '',
        this.profile = ''
        await axios.post('http://localhost:5000/api/speakers', {speaker}, getToken)
          .then(response => {
            console.log(response.data)
            response.data})

      } catch (error){
         console.log(error.response.data);  
         console.log(error.response.status);  
         console.log(error.response.headers);
      }
    },
    async getSpeaker() {
      try {
        const getToken = {
          headers: {
            Authorization: process.env.VUE_APP_WP_API_KEY,
            'Content-Type': 'application/json; charset=utf-8'
          }
        }

        const dataSpeakers = await axios.get('http://localhost:5000/api/speakers', getToken)
        .then(response => response.data)
        return {dataSpeakers}
      } catch (error) {
         console.log(error.response.data);  
         console.log(error.response.status);  
         console.log(error.response.headers); 
      }
    },
    editSpeaker(speaker) {
      this.currentSpeaker = {
        name: speaker.name,
        lastName: speaker.lastName,
        description: speaker.description,
        profile: speaker.profile,
        id: speaker.id,
      }
      console.log(speaker.id)
    },
    async guardarSpeaker() {
      axios({
          method: 'put',
          url: 'http://localhost:5000/api/speakers/' + this.currentSpeaker.id,
          data: {
              fieldsToUpdate: this.currentSpeaker
          },
          headers: {
            Authorization: process.env.VUE_APP_WP_API_KEY,
            'Content-Type': 'application/json; charset=utf-8'
          },
      }).then(() => {
        this.dataSpeakers = this.dataSpeakers.map(speaker => {
          if (speaker.id === this.currentSpeaker.id) {
            return this.currentSpeaker
          } else {
            return speaker
          }
        })
      });
    },
    deleteSpeaker(speaker) {
      axios({
          method: 'delete',
          url: 'http://localhost:5000/api/speakers/' + speaker.id,
          headers: {
            Authorization: process.env.VUE_APP_WP_API_KEY,
            'Content-Type': 'application/json; charset=utf-8'
          },
      }).then(() => {
        this.dataSpeakers = this.dataSpeakers.filter(r => {
          if (r.id !== speaker.id) {
            return r
          }
        })
      });
    }
  }
};
</script>

<style>
.btn-cargar-user {
  border: none;
  margin-bottom: 10px;
  font-size: 13px;
  border-radius: 10px;
  width: 125px;
  height: 40px;
}
</style>
