<template>
  <div class="card mb-4">
    <div class="card-header pb-0">
      <h6>Tabla de usuarios</h6>
      <!-- <button class="btn">Cargar usuarios</button> -->
      <!-- Button trigger modal -->
      <button
        type="button"
        class="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        Carga auspiciante
      </button>

      <!-- Modal -->
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="2"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Cargar auspiciante
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <form @submit.prevent="addPartner">
                <div class="form-group">
                  <label for="nombre">Nombre</label>
                  <input
                    type="text"
                    class="form-control"
                    id="nombre"
                    placeholder="Nombre"
                    v-model.trim="name"
                    required
                  />
                </div>
                <button
                  class="btn btn-primary"
                  depressed 
                  @click="uploadFile"
                  :disabled="isUploadingFile"
                  >
                  Cargar Imagen</button>
                <div style="clear: both;"></div>
                <input 
                  type="file" 
                  ref="fileInputRef" 
                  id="file-input-hidden"
                  v-on:change="handleFile"
                />
                <div v-if="isUploadingFile">      
                </div>
                <div v-else-if="showImageProp">
                    <div>
                        <img :src="urlImage" alt="">
                    </div>
                </div>
                  <br><br>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Cancelar
                  </button>
                  <button 
                    type="submit" 
                    class="btn btn-primary"
                    >Guardar</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="editModal"
        tabindex="2"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Editar auspiciante
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <form>
                <div class="form-group">
                  <label for="nombre">Nombre</label>
                  <input
                    type="text"
                    class="form-control"
                    id="nombre"
                    placeholder="Nombre"
                    v-model="currentPartner.name"
                  />
                </div>
                <button class="btn btn-primary">Cargar imagen</button>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Cancelar
                  </button>
                  <button 
                    type="button" 
                    class="btn btn-primary"
                    v-on:click="guardarPartner"
                    >Guardar</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Imagen
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Nombre
              </th>
              <th class="text-secondary opacity-7"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="partner in dataPartners" 
              :key="partner.id">
              <td>
                <div class="d-flex px-2 py-1">
                  <div>
                    <vsud-avatar
                      :img="img1"
                      size="sm"
                      border-radius="lg"
                      class="me-3"
                      alt="user1"
                    />
                  </div>
                </div>
              </td>
              <td>
                <p class="text-xs text-secondary mb-0">{{partner.name}}</p>
              </td>
              <td class="align-middle text-center">
                <button
                  type="button"
                  class="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#editModal"
                  @click="editPartner(partner)"
                >
                  Editar
                </button>
              </td>
              <td class="align-middle text-center">
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="deletePartner(partner)"
                >
                  Eliminar
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import VsudAvatar from "@/components/VsudAvatar.vue";
// import VsudBadge from "@/components/VsudBadge.vue";

import img1 from "../assets/img/team-2.jpg";
import img2 from "../assets/img/team-3.jpg";
import img3 from "../assets/img/team-4.jpg";
import img4 from "../assets/img/team-3.jpg";
import img5 from "../assets/img/team-2.jpg";
import img6 from "../assets/img/team-4.jpg";

import axios from 'axios'

export default {
  name: "authors-table",
  props: {
    showImage: {
      type: Boolean
    },
  },
  data: () => ({
    name: '',
    image: '',
    isUploadingFile: false,
    dataPartners: [],
    currentPartner: {
      name: '',
      image: '',
      img1,
      img2,
      img3,
      img4,
      img5,
      img6,
    }
  }),
  components: {
    VsudAvatar,
    // VsudBadge,
  },
  computed: {
    showImageProp() {
      return this.showImage
    },
  },
  mounted() {
    this.getPartner()
  },
  methods: {
    async addPartner() {
      try {
        const getToken = {
          headers: {
            Authorization: process.env.VUE_APP_WP_API_KEY,
            'Content-Type': 'application/json; charset=utf-8'
          }
        }
        const partner = {
          name: this.name,
          image: this.image
        }
        this.dataPartners.push(partner)        
        this.name = '',
        this.image = '',
        await axios.post('http://localhost:5000/api/partners', {partner}, getToken)
          .then(response => {
            console.log(response.data)
            response.data})

      } catch (error){
         console.log(error.response.data);  
         console.log(error.response.status);  
         console.log(error.response.headers);
      }
    },
    async getPartner() {
      try {
        const getToken = {
          headers: {
            Authorization: process.env.VUE_APP_WP_API_KEY,
            'Content-Type': 'application/json; charset=utf-8'
          }
        }

        this.dataPartners = await axios.get('http://localhost:5000/api/partners', getToken)
        .then(response => response.data.partners)
      } catch (error) {
         console.log(error.response.data);  
         console.log(error.response.status);  
         console.log(error.response.headers); 
      }
    },
    editPartner(partner) {
      this.currentPartner = {
        name: partner.name,
        image: partner.image,
        id: partner.id,
      }
      console.log(partner.id)
    },
    async guardarPartner() {
      axios({
          method: 'put',
          url: 'http://localhost:5000/api/partners/' + this.currentPartner.id,
          data: {
              fieldsToUpdate: this.currentPartner
          },
          headers: {
            Authorization: process.env.VUE_APP_WP_API_KEY,
            'Content-Type': 'application/json; charset=utf-8'
          },
      }).then(() => {
        this.dataPartners = this.dataPartners.map(partner => {
          if (partner.id === this.currentPartner.id) {
            return this.currentPartner
          } else {
            return partner
          }
        })
      });
    },
    deletePartner(partner) {
      axios({
          method: 'delete',
          url: 'http://localhost:5000/api/partners/' + partner.id,
          headers: {
            Authorization: process.env.VUE_APP_WP_API_KEY,
            'Content-Type': 'application/json; charset=utf-8'
          },
      }).then(() => {
        this.dataPartners = this.dataPartners.filter(r => {
          if (r.id !== partner.id) {
            return r
          }
        })
      });
    },
    uploadFile() {
        this.$refs.fileInputRef.click()
      },
    handleFile(e) {
        this.isUploadingFile = true;
        var bodyFormData = new FormData();
        console.log(e.target.files)
        bodyFormData.append('fileName', e.target.files[0]); 
        this.$axios({
        method: "post",
        url: "/uploadImg",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          this.auspicianteValues.image = response.data.url;
          this.image = response.data.url
          this.isUploadingFile = false;
        })
        .catch((response) => {
          console.log(response);
          this.isUploadingFile = false;
        });
    }
  }
};
</script>

<style>
.btn-cargar-user {
  border: none;
  margin-bottom: 10px;
  font-size: 13px;
  border-radius: 10px;
  width: 125px;
  height: 40px;
}
</style>
