import { defineStore } from 'pinia';

import { fetchWrapper } from '@/helpers';
import router from '@/router';

let baseUrl;
if (process.env.NODE_ENV === "development") 
{
  baseUrl = process.env.VUE_APP_BASE_URL_DEV;
} 
else 
{
  baseUrl = process.env.VUE_APP_BASE_URL;
}


export const useAuthStore = defineStore({
    id: 'auth',
    state: () => ({
        // initialize state from local storage to enable user to stay logged in
        user: JSON.parse(localStorage.getItem('user')),
        returnUrl: null
    }),
    actions: {
        async login(email, password, id_contract) {
            
            const user = await fetchWrapper.post(`${baseUrl}/v1/login`, { email, password, id_contract });

            if(user.status === 0)
            {
                console.log("user", user);
                alert(user.message);
                return
            }
            
            // update pinia state
            this.user = user;

            // store user details and jwt in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(user));

            // redirect to previous url or default to home page
            router.push(this.returnUrl || '/');
        },
        
        logout() {
            this.user = null;
            localStorage.removeItem('user');
            router.push('/sign-in');
        }
    }
});
