<template>
    <div class="card" style="max-heigt:700px">
        <div class="card-header pb-0">
            <div class="row">
                <h6>Asistentes</h6>
            </div>
        </div>
        <div class="card-body px-0" style="
    max-height: 700px;
    overflow: auto;
">
            <div class="table-responsive">
                <table class="table align-items-center mb-0">
                    <thead>
                        <tr>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                Nombre
                            </th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                Email
                            </th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                Permanencia estimada
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="user in metrics" :key="user.id">
                            <td>
                                <div class="d-flex px-2 py-1">
                                    <div>
                                        <div class="avatar avatar-sm me-3">
                                            <img src="https://cdn-icons-png.flaticon.com/512/6073/6073874.png">
                                        </div>
                                    </div>
                                    <div class="d-flex flex-column justify-content-center">
                                        <h6 class="mb-0 text-sm">{{ user.name }}</h6>
                                    </div>
                                </div>
                            </td>
                            <td class="align-middle text-center text-sm">
                                <span class="text-xs">{{ user.email }}</span>
                            </td>
                            <td class="align-middle text-center text-sm">
                                <span class="text-xs font-weight-bold">{{ user.total_minutes }} minutos</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>


<script>
import { fetchWrapper } from '@/helpers';
//import GradientLineChart from "@/examples/Charts/GradientLineChart.vue";

export default {
    name: "Asistentes",
    data() {
        return {
            iconBackground: "bg-gradient-primary",
            metrics: []
        };
    },
    components: {
        //GradientLineChart
    },
    created() {
        this.getData();
    },
    methods: {
        async getData() {

            let from = "2022-11-01"
            let to = "2022-11-05";
            const baseUrl = process.env.VUE_APP_BASE_URL;
            const url = `${baseUrl}/v1/enter_leaves_metrics/users_data_uniques_between_dates/1/${from}/${to}`;

            // enviar con axios esta solicitud, si es ok, lo envía a login
            fetchWrapper.get(url)
                .then(result => {
                    this.metrics = result;
                })
                .catch(error => console.log(error))
        }
    }
};
</script>