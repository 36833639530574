import { createStore } from "vuex";
import bootstrap from "bootstrap/dist/js/bootstrap.min.js";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import axios from 'axios';
let baseUrl;

if (process.env.NODE_ENV === "development") 
{
  baseUrl = process.env.VUE_APP_BASE_URL_DEV;
} 
else 
{
  baseUrl = process.env.VUE_APP_BASE_URL;
}

export default createStore({
  state: {
    hideConfigButton: false,
    isPinned: true,
    showConfig: false,
    isTransparent: "",
    isRTL: false,
    color: "",
    isNavFixed: false,
    isAbsolute: false,
    showNavs: true,
    showSidenav: true,
    showNavbar: true,
    showFooter: true,
    showMain: true,
    navbarFixed:
      "position-sticky blur shadow-blur left-auto top-1 z-index-sticky px-0 mx-4",
    absolute: "position-absolute px-4 mx-0 w-100 z-index-2",
    bootstrap,

    /// TESTS
    user_login_auth: {
      isLoggedIn: false,
      user: {}
    }

    /// _Change for Proyect Name_
  },

  mutations: {
    toggleConfigurator(state) {
      state.showConfig = !state.showConfig;
    },
    navbarMinimize(state) {
      const sidenav_show = document.querySelector(".g-sidenav-show");
      if (sidenav_show.classList.contains("g-sidenav-hidden")) {
        sidenav_show.classList.remove("g-sidenav-hidden");
        sidenav_show.classList.add("g-sidenav-pinned");
        state.isPinned = true;
      } else {
        sidenav_show.classList.add("g-sidenav-hidden");
        sidenav_show.classList.remove("g-sidenav-pinned");
        state.isPinned = false;
      }
    },
    sidebarType(state, payload) {
      state.isTransparent = payload;
    },
    cardBackground(state, payload) {
      state.color = payload;
    },
    navbarFixed(state) {
      if (state.isNavFixed === false) {
        state.isNavFixed = true;
      } else {
        state.isNavFixed = false;
      }
    },
    toggleEveryDisplay(state) {
      state.showNavbar = !state.showNavbar;
      state.showSidenav = !state.showSidenav;
      state.showFooter = !state.showFooter;
    },
    toggleHideConfig(state) {
      state.hideConfigButton = !state.hideConfigButton;
    },

    /// TESTS
    authUser(state, payload) {
      state.user_login_auth = payload;
    },

    login(state, payload) {
      state.user_login_auth = payload;
    }

    /// _NOMBRE PROYECTO _
  },

  actions: {
    toggleSidebarColor({ commit }, payload) {
      commit("sidebarType", payload);
    },
    setCardBackground({ commit }, payload) {
      commit("cardBackground", payload);
    },

    /// TESTS
    setAuthUser({ commit }, payload) {
      commit("authUser", payload);
    },

    login({ commit }, payload) {
      axios({
        method: 'POST',
        url: '/auth/login',
        baseURL: baseUrl,
        data: {
          email: payload.email,
          password: payload.password,
        }
      }).then(() => {
        
        const auth = getAuth();
        signInWithEmailAndPassword(auth, payload.email, payload.password)
          .then((userCredential) => {
            let user_login_auth = {
              isLoggedIn: true,
              user: userCredential.user
            };
            commit("login", user_login_auth);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch(err => {
        console.log(err)
      });
    },

    /// _NOMBRE PROYECTO _
  },

  getters: {},
  
});
