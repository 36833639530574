<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar is-blur="blur blur-rounded my-3 py-2 start-0 end-0 mx-4 shadow" btn-background="bg-gradient-primary"
          :dark-mode="true" />
      </div>
    </div>
  </div>
  <main class="mt-0 main-content main-content-bg">
    <section>
      <div class="page-header min-vh-75">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-6 d-flex flex-column">
              <div class="mt-8 card card-plain">
                <div class="pb-0 card-header text-start" style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                  ">
                  <h3 class="font-weight-bolder" style="color: #eb1e79">
                    Bienvenido a Roomin
                  </h3>
                  <p class="mb-0">Ingrese su credenciales</p>
                </div>
                <div class="card-body">
                  <form role="form" class="text-start" v-on:submit="login">
                    <label>Email</label>
                    <input id="email" type="email" class="form-control" placeholder="Email" name="email" v-model="email" />

                    <label>Password</label>
                    <input id="password" class="form-control" type="password" placeholder="Password" name="password" v-model="password"/>
                      
                    <vsud-switch id="rememberMe" name="rememberMe" checked>
                      Recordarme
                    </vsud-switch>
                    <div class="text-center">
                      <vsud-button class="my-4 mb-2" style="background-color: #eb1e79" full-width>Iniciar Sesión
                      </vsud-button>
                    </div>
                  </form>
                </div>
                <!-- <div class="px-1 pt-0 text-center card-footer px-lg-2">
                  <p class="mx-auto mb-4 text-sm">
                    Olvidates tu contraseña?
                    <router-link
                      :to="{ name: 'Sign Up' }"
                      style="color: #eb1e79"
                      >Recuperar</router-link
                    >
                  </p>
                </div> -->
              </div>
            </div>
            <div class="col-md-6">
              <div class="top-0 oblique position-absolute h-100 d-md-block d-none me-n8">
                <div
                  class="bg-cover oblique-image position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6 fondo-signin">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
  <app-footer />
</template>

<style>
.fondo-signin {
  background-image: url("../assets/img/curved-images/fondo-2.png");
  background-position-x: -80px;
  background-position-y: 80px;
  background-size: contain !important;
}
</style>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
//import VsudInput from "@/components/VsudInput.vue";
import VsudSwitch from "@/components/VsudSwitch.vue";
import VsudButton from "@/components/VsudButton.vue";
import { useAuthStore } from '@/stores';


const body = document.getElementsByTagName("body")[0];

import { mapMutations } from "vuex";

export default {
  name: "SignIn",
  data() {
    return {
      email: "hipolito.paradela@raxar.com.ar",
      password: "Abcd1234",
      id_contract: "1"
    };
  },
  components: {
    Navbar,
    AppFooter,
    VsudSwitch,
    VsudButton,
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),

    login(e) {
      e.preventDefault();
      const authStore = useAuthStore();

      return authStore.login(this.email, this.password, this.id_contract)
        .catch(error => console.log(error));
      /* let user = {
        email: this.email,
        password: this.password,
      };
      this.$store.dispatch("login", user); */
    },
  },
};
</script>
