<template>
  <div class="card mb-4">
    <div class="card-header pb-0">
      <h6>Tabla de usuarios</h6>
      <!-- <button class="btn">Cargar usuarios</button> -->
      <!-- Button trigger modal -->
      <button
        type="button"
        class="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        Carga ajuste
      </button>

      <!-- Modal -->
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="2"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Cargar ajuste</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <form @submit.prevent="addSetting">
                <div class="form-group">
                  <label for="nombre">Nombre</label>
                  <input
                    type="text"
                    class="form-control"
                    id="nombre"
                    placeholder="Nombre"
                    v-model.trim="name"
                    required
                  />
                </div>
                <div class="form-group">
                  <label for="descripcion">Descripcion</label>
                  <input
                    type="text"
                    class="form-control"
                    id="descripcion"
                    placeholder="Descripcion"
                    v-model.trim="description"
                    required
                  />
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Cancelar
                  </button>
                  <button 
                    type="submit" 
                    class="btn btn-primary"
                    
                    >Guardar</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="editModal"
        tabindex="2"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Editar ajuste</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <form>
                <div class="form-group">
                  <label for="nombre">Nombre</label>
                  <input
                    type="text"
                    class="form-control"
                    id="nombre"
                    placeholder="Nombre"
                    v-model="currentSetting.name"
                  />
                </div>
                <div class="form-group">
                  <label for="descripcion">Descripcion</label>
                  <input
                    type="text"
                    class="form-control"
                    id="descripcion"
                    placeholder="Descripcion"
                    v-model="currentSetting.description"
                  />
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Cancelar
                  </button>
                  <button 
                    type="submit" 
                    class="btn btn-primary"
                    v-on:click="guardarSetting"
                    >Guardar</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Usuario
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Descripcion
              </th>
              <th class="text-secondary opacity-7"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="setting in dataSettings" 
              :key="setting.id">
              <td>
                <div class="d-flex px-2 py-1">
                  <div>
                    <vsud-avatar
                      :img="img1"
                      size="sm"
                      border-radius="lg"
                      class="me-3"
                      alt="user1"
                    />
                  </div>
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">{{setting.name}}</h6>
                  </div>
                </div>
              </td>
              <td>
                <p class="text-xs text-secondary mb-0">{{setting.description}}</p>
              </td>
              <td class="align-middle text-center">
                <button
                  type="button"
                  class="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#editModal"
                  @click="editSetting(setting)"
                >
                  Editar
                </button>
              </td>
              <td class="align-middle text-center">
                <button
                  type="button"
                  class="btn btn-danger"
                  @click="deleteSetting(setting)"
                >
                  Eliminar
                </button>
              </td>

              <td class="align-middle"></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import VsudAvatar from "@/components/VsudAvatar.vue";
// import VsudBadge from "@/components/VsudBadge.vue";
import "@vuepic/vue-datepicker/dist/main.css";

import img1 from "../assets/img/team-2.jpg";
import img2 from "../assets/img/team-3.jpg";
import img3 from "../assets/img/team-4.jpg";
import img4 from "../assets/img/team-3.jpg";
import img5 from "../assets/img/team-2.jpg";
import img6 from "../assets/img/team-4.jpg";

import axios from 'axios'

export default {
  name: "authors-table",
  data: () => ({
    name: '',
    description: '',
    dataSettings: [],
    currentSetting: {
      name: '',
      description: ''
    },
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
  }),
  components: {
    VsudAvatar,
    // VsudBadge,
  },
  mounted() {
    this.getSetting()
  },
  methods: {
    async addSetting() {
      try {
        const getToken = {
          headers: {
            Authorization: process.env.VUE_APP_WP_API_KEY,
            'Content-Type': 'application/json; charset=utf-8'
          }
        }
        const setting = {
          name: this.name,
          description: this.description
        }
        this.dataSettings.push(setting)        
        this.name = '',
        this.description = ''
        await axios.post('http://localhost:5000/api/settings', {setting}, getToken)
          .then(response => {
            console.log(response.data)
            response.data})

      } catch (error){
         console.log(error.response.data);  
         console.log(error.response.status);  
         console.log(error.response.headers);
      }
    },
    async getSetting() {
      try {
        const getToken = {
          headers: {
            Authorization: process.env.VUE_APP_WP_API_KEY,
            'Content-Type': 'application/json; charset=utf-8'
          }
        }

        this.dataSettings = await axios.get('http://localhost:5000/api/settings', getToken)
        .then(response => response.data.settings)
      } catch (error) {
        //  console.log(error.response.data);  
         console.log(error.response.status);  
         console.log(error.response.headers); 
      }
    },
    editSetting(setting) {
      this.currentSetting = {
        name: setting.name,
        description: setting.description,
        id: setting.id,
      }
      console.log(setting.id)
    },
    async guardarSetting() {
      axios({
          method: 'put',
          url: 'http://localhost:5000/api/settings/' + this.currentSetting.id,
          data: {
              fieldsToUpdate: this.currentSetting
          },
          headers: {
            Authorization: process.env.VUE_APP_WP_API_KEY,
            'Content-Type': 'application/json; charset=utf-8'
          },
      }).then(() => {
        this.dataSettings = this.dataSettings.map(setting => {
          if (setting.id === this.currentSetting.id) {
            return this.currentSetting
          } else {
            return setting
          }
        })
      });
    },
    deleteSetting(setting) {
      axios({
          method: 'delete',
          url: 'http://localhost:5000/api/settings/' + setting.id,
          headers: {
            Authorization: process.env.VUE_APP_WP_API_KEY,
            'Content-Type': 'application/json; charset=utf-8'
          },
      }).then(() => {
        this.dataSettings = this.dataSettings.filter(r => {
          if (r.id !== setting.id) {
            return r
          }
        })
      });
    }
  }
};
</script>

<style>
.btn-cargar-user {
  border: none;
  margin-bottom: 10px;
  font-size: 13px;
  border-radius: 10px;
  width: 125px;
  height: 40px;
}
</style>
