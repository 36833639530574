/**
=========================================================
* Roomin  Dashboard - v2.0.0
=========================================================

* Product Page: https://creative-tim.com/product/vue-soft-ui-dashboard
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { createApp } from "vue";
import axios from 'axios';
import VueAxios from 'vue-axios';
import { createPinia } from 'pinia';
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import SoftUIDashboard from "./soft-ui-dashboard";
import { initializeApp } from "firebase/app";

/* code from our Firebase console */
var firebaseConfig = {
  apiKey: "AIzaSyBtUxIAq6hoIxz5YtkJpl16K_oo0wb02Xc",
  authDomain: "greenar-practica.firebaseapp.com",
  databaseURL: "https://greenar-practica-default-rtdb.firebaseio.com",
  projectId: "greenar-practica",
  storageBucket: "greenar-practica.appspot.com",
  messagingSenderId: "327987098269",
  appId: "1:327987098269:web:6e2d3e0cf4e741facc5a74",
  measurementId: "G-4VDCQMGX4C"
};

// Initialize Firebase
initializeApp(firebaseConfig);

const pinia = createPinia()
const appInstance = createApp(App);

appInstance.use(pinia)
appInstance.use(store);
appInstance.use(router);
appInstance.use(SoftUIDashboard);
appInstance.use(VueAxios, axios)
appInstance.mount("#app");
